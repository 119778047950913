var exports = $.extend({}, require('base/search/search'));
var SiteConstants = require('constants/SiteConstants');
var headerUtils = require('../utilities/headerUtils');
var wishlistHelpers = require('core/wishlist/components/helpers');

var setRefinementCollapseStates = status => {

    if (status === 'open') {
        $('.refinement').addClass('active');
    }
    else if (status === 'closed') {
        $('.refinement').removeClass('active');
        $('.refinement').find('button.title').attr('aria-expanded','false');
    }
};

var toggleRefinementDrawer = status => {
    var $refinementBar = $('.refinement-bar');
    var $modalBackground = $('.modal-background');

    if (status === 'open') {
        var headerNavHeight = headerUtils.getHeaderHeight();
        var scrollTopHeight = $('header').offset().top;

        $('html').scrollTop(scrollTopHeight);
        // Following two lines for MS Edge to work
        document.body.scrollTop = scrollTopHeight;
        document.documentElement.scrollTop = scrollTopHeight;
        $('html').addClass('lock-scroll');
        $('body').addClass('mobile-filter-drawer-in');
        $refinementBar.addClass('in').css('top', headerNavHeight).siblings().attr('aria-hidden', true);
        $modalBackground.fadeIn(SiteConstants.TransitionSpeed).css('top', headerNavHeight);
        $refinementBar.closest('.row').siblings().attr('aria-hidden', true);
        $refinementBar.closest('.tab-pane.active').siblings().attr('aria-hidden', true);
        $refinementBar.closest('.container.search-results').siblings().attr('aria-hidden', true);
        $refinementBar.find('.close').focus();
    } else {
        $('html').removeClass('lock-scroll');
        $('body').removeClass('mobile-filter-drawer-in');
        $refinementBar.removeClass('in').siblings().attr('aria-hidden', false);
        $modalBackground.fadeOut(SiteConstants.TransitionSpeed);
        $refinementBar.closest('.row').siblings().attr('aria-hidden', false);
        $refinementBar.closest('.tab-pane.active').siblings().attr('aria-hidden', false);
        $refinementBar.closest('.container.search-results').siblings().attr('aria-hidden', false);
        $('.btn.filter-results').focus();
    }
};

var toggleFilters = (e) => {
    e.preventDefault();
    var toggleDirection = 'open';
    if ($('.refinement-bar .refinement').hasClass('active')) {
        toggleDirection = 'closed';
    }
    setRefinementCollapseStates(toggleDirection);
};

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    var showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            $target.append(response);
        },
        complete: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response, promoTileCount) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    var pageSize = Number($tempDom.find('.grid-footer').data('page-size'));

    sortOptions.forEach(option => {
        var pageUrl = new URL(window.location);
        var urlPageSize = pageUrl.searchParams.get('sz') !== null ? Number(pageUrl.searchParams.get('sz')) : null;
        var optionUrl = new URL(option.url);
        var optionPageSize = Number(optionUrl.searchParams.get('sz'));

        // If 'sz' is already defined in URL, but promo tiles aren't already accounted for, decrement sz param
        if (!isNaN(urlPageSize) && urlPageSize !== optionPageSize - pageSize) {
            var updatedPageSize = optionPageSize - promoTileCount;
            optionUrl.searchParams.set('sz', updatedPageSize);
            option.url = optionUrl.toString();
        }

        $('option.' + option.id).val(option.url);
    });
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results, selector) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });
    updateDom($results, selector);
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var isMobile = window.isMobile();

    if (isMobile) {
        var $refinements = $results.find('.refinements[data-refinement-style-mobile]');
        var refinementStyle = $refinements.data('refinement-style-mobile');
        var selector = `[data-refinement-style-mobile="${refinementStyle}"]`;
    } else {
        var $refinements = $results.find('.refinements[data-refinement-style-desktop]');
        var refinementStyle = $refinements.data('refinement-style-desktop');
        var selector = `[data-refinement-style-desktop="${refinementStyle}"]`;
    };

    var $filterBar = $results.find('.filter-bar');
    var filterBarStyle = !isMobile ? $filterBar.attr('data-filter-bar-style-desktop') : $filterBar.attr('data-filter-bar-style-mobile');
    var filterBar = !isMobile ? '[data-filter-bar-style-desktop="' + filterBarStyle + '"]' : '[data-filter-bar-style-mobile="' + filterBarStyle + '"]';

    var verticalMobile = isMobile && selector === '[data-refinement-style-mobile="vertical"]';
    var verticalDesktop = !isMobile && selector === '[data-refinement-style-desktop="vertical"]';
    var horizontalMultipleDropdownsDesktop = !isMobile && selector === '[data-refinement-style-desktop="horizontal-multiple-dropdowns"]';
    var horizontalOneDropdownDesktop = !isMobile && selector === '[data-refinement-style-desktop="horizontal-one-dropdown"]';
    var horizontalOneDropdownDesktopActive = $('.collapse-filters-wrapper').hasClass('active');
    var horizontalOneDropdownDesktopOpen = false;

    if (horizontalOneDropdownDesktop && horizontalOneDropdownDesktopActive) {
        horizontalOneDropdownDesktopOpen = true;
    }

    if (verticalMobile || verticalDesktop) {
        var specialHandlers = {
            selector : handleRefinements
        };

        Object.keys(specialHandlers).forEach(function (selector) {
            specialHandlers[selector]($results, selector);
        });
    };

    if (!isMobile) {
        var refinements = '.desktop-search-refinements';
    } else {
        var refinements = '.mobile-search-refinements';
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        refinements,
        filterBar
    ].forEach((selector) => {
        updateDom($results, selector);
    });

    if (horizontalOneDropdownDesktopOpen === true) {
        $('.collapse-filters-wrapper').addClass('active').css('display', 'block');
    }

    $('body').trigger('ajax:load.ajaxEvents', [$('.search-results')]);

    exports.refinementStickyBar();
    exports.oneDropdownSlideToggle();
}

exports.oneDropdownSlideToggle = function() {
    var oneDropdownFilter = $('.horizontal-onedropdown-filter');
    if (oneDropdownFilter) {
        var menuToggle = $('.horizontal-onedropdown-filter-btn').unbind();
        menuToggle.removeClass('open');

        menuToggle.on('touchstart click', function(e) {
            e.preventDefault();
            $('.collapse-filters-wrapper').slideToggle(SiteConstants.TransitionSpeed).toggleClass('active');
            menuToggle.toggleClass('open');
        });
    }
}

exports.refinementStickyBar = function() {
    var showStickyRefinementData = document.getElementById('horizontal-filter');
    if (showStickyRefinementData) {
        var showStickyRefinementBarCheck = showStickyRefinementData.getAttribute('data-sticky-refinement-bar');
        var topHeader = document.getElementById('top-header'); // includes promobar and nav
        var headerNavHeight = document.querySelector('.header-nav').offsetHeight;
        var mainContent = document.getElementById('maincontent');
        var isMobile = window.isMobile();

        //if header is fixed, use just the nav bar to measure top of page, else use the entire header (may contain promo bar)
        if (topHeader.classList.contains('fixed-header')) {
            var topHeaderHeight = headerNavHeight;
            var heightCalculation = (document.querySelector('.search-results').offsetTop - headerNavHeight);
        } else {
            var topHeaderHeight = 0;
            var heightCalculation = document.querySelector('.search-results').offsetTop;
        }

        if (showStickyRefinementData && !isMobile && showStickyRefinementBarCheck == 'true') {
            // Note: This is intentionally not using utilities/scroll.js so that it can fix immediately vs. being debounced. Should be replaced with CSS position:sticky once IE11 support is dropped.
            window.addEventListener('scroll', function() {
                //main sticky horiziontal dropdown filter functionality - only show when scrolled past in-page refinement button section
                if (window.pageYOffset > heightCalculation && mainContent.scrollHeight > (window.innerHeight + showStickyRefinementData.offsetHeight)) {
                    showStickyRefinementData.classList.add('sticky-filters');
                    showStickyRefinementData.setAttribute('style', 'top:' + topHeaderHeight + 'px;');
                    mainContent.setAttribute('style', 'padding-top:' + showStickyRefinementData.offsetHeight + 'px;');
                } else if (showStickyRefinementData.classList.contains('sticky-filters') && window.pageYOffset < heightCalculation) {
                    showStickyRefinementData.classList.remove('sticky-filters');
                    mainContent.removeAttribute('style');
                }
            });
        }
    }
}

var closeOneRefinementsDropdown = (e) => {
    var $oneRefinementsDropdown = $('.horizontal-onedropdown-filter-group');
    if ((!$('.collapse-one-dropdown-filter').is(e.target) && $oneRefinementsDropdown.has(e.target).length === 0)
        || $('.close-one-refinements-dropdown').is(e.target)) {
            $('.collapse-filters-wrapper').slideUp().removeClass('active');
    }
};

exports.initialize = () => $(document).ready(function () {
    var isMobile = window.isMobile();
    var collapseMobile = $('[data-collapse-mobile]').attr('data-collapse-mobile') === 'true' ? true : false;
    var collapseDesktop = $('[data-collapse-desktop]').attr('data-collapse-desktop') === 'true' ? true : false;
    if (isMobile && collapseMobile) {
        setRefinementCollapseStates('close');
    } else if (isMobile && !collapseMobile) {
        setRefinementCollapseStates('open');
    } else if (!isMobile && collapseDesktop) {
        setRefinementCollapseStates('close');
    } else if (!isMobile && !collapseDesktop) {
        setRefinementCollapseStates('open');
    }
    if ($('#articles-tab').hasClass('active') && $('#content-search-results').html() === '') {
        getContent($('.content-search'), $('#content-search-results'));
    }

    var refinementCategoryOpen = localStorage.getItem('refinement-category');
    if (refinementCategoryOpen) {
        $('.refinement-category').addClass('active');
    }
});

exports.closeRefinements = function () {

    $('html').on('click', '.refinement-bar button.close, .mobile-filter-drawer-in .modal-background', () => toggleRefinementDrawer('close'));
    //for horizontal one dropdown menu
    $('html').on('click', (e) => closeOneRefinementsDropdown(e));
}

exports.sort = function () {
    var $xhr;
    // Handle sort order menu selection
    $('.container').on('change', '[name=sort-order]', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var thisValue = this.value;
        $(this).trigger('search:sort', thisValue);
        var errorMsg = $(this).data().errorMsg;

        var srule = thisValue || '';
        srule = srule.indexOf('srule=') !== -1 ? srule.replace(/^.+srule\=/,'').replace(/\&.+$/,'') : '';
        var newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?';
        // Rebuild url without srule
        var params = window.location.search?.replace(/^\?/g,'').split('&')?.filter((param) => ['srule','',null,undefined].indexOf(param?.split('=')?.[0]) === -1 ); // only include param is key exists & NOT srule
        // Add Srule
        params.push('srule=' + srule);
        // Join URL w/ Params
        newUrl += params.join('&');

        var newSelection = this.selectedOptions[0];
        $('[name="sort-order"]').find('option[selected="selected"], option:selected').prop('selected',false).removeAttr('selected');
        $(newSelection).prop('selected', true).attr('selected', true);

        // Cancel previous request
        $xhr && $xhr.abort && $xhr.abort();

        $xhr = $.ajax({
            url: thisValue,
            data: {
                selectedUrl: thisValue,
                isSortUpdate: true
            },
            method: 'GET',
            timeout: 10000,
            beforeSend: function () {
                $.spinner().start();
            },
            success: function (response) {
                var $response = $(response);
                var $productGridElement = $response.find('.product-grid'); // if response is coming from page designer
                var permalink = $response.find(':input.permalink').val();
                var updatedGridHtml = $productGridElement.length > 0 ? $productGridElement.children() : response;
                $('.product-grid').empty().html(updatedGridHtml);

                // Help ensure the correct page is returned when PLP page is cached
                if (permalink) {
                    history.replaceState(undefined, '', permalink);
                } else {
                    history.replaceState(undefined, '', newUrl);
                }

                $('body').trigger('search:sort--success');
                $('body').trigger('ajax:load.ajaxEvents', [$('.product-grid')]);
            },
            complete: function () {
                $.spinner().stop();
            }
        });
    });
}

exports.searchShared$XHR = null;

exports.showMore = function () {
    // Show more products
    $('.container').on('click', '.show-more button', function (e) {
        e.stopPropagation();
        e.preventDefault();
        var showMoreUrl = $(this).data('url');
        var $showMoreButton = $(e.target);
        $.spinner().start();
        $(this).trigger('search:showMore', e);

        // Cancel previous request
        module.exports.searchShared$XHR?.abort?.();

        module.exports.searchShared$XHR = $.ajax({
            url: showMoreUrl,
            method: 'GET',
            success: function (response) {
                var $response = $(response);
                var $productGridElement = $response.find('.product-grid'); // if response is coming from page designer
                var updatedGridHtml = $productGridElement.length > 0 ? $productGridElement.children() : response;

                var promoTileCount = $showMoreButton.closest('.product-grid').find('[class*="experience-promoTile"]').length;
                $('.grid-footer').replaceWith(updatedGridHtml);
                var permalink = $(response).find(':input.permalink').val();

                // Help ensure the correct page is returned when PLP page is cached
                if (permalink) {
                    history.replaceState(undefined, document.title, permalink);
                }

                updateSortOptions(response, promoTileCount);
                wishlistHelpers.updateLinkData();
                $('body').trigger('search:showMore--success');
            },
            complete: function () {
                $.spinner().stop();
            }
        });
    });
};

exports.applyFilter = function () {
    var $xhr;
    // Handle refinement value selection and reset click
    $('.container').on(
        'click',
        '.refinements li button, .refinement-bar button.reset, .filter-value button, .swatch-filter button',
        function (event) {
            var category = $(event.currentTarget).closest('.refinement');

            if (category && category.hasClass('refinement-category')) {
                localStorage.setItem('refinement-category', true);
                return;
            }
            //find new attr on Sort if selected, if not use the default
            var selectedSort = $('select[name="sort-order"]').find('option[selected="selected"]');
            if (selectedSort.data('id') != null) {
                var sortingRule = selectedSort.data('id');
            } else {
                var sortingRule = $('select[name="sort-order"] option:selected').data('id');
            }
            //create a new url with the correct preferences
            if ($(this).data('href').includes('srule')) {
                var refinementUrl = $(this).data('href').replace(/(srule=).*?(&|$)/,'$1' + sortingRule + '$2');
            } else if (!$(this).data('href').includes('srule') && sortingRule != null) {
                var split = $(this).data('href').indexOf('?') !== -1 ? '&' : '?';
                var refinementUrl = $(this).data('href') + split + 'srule=' + sortingRule;
            } else {
                var refinementUrl = $(this).data('href');
            }

            event.preventDefault();
            event.stopPropagation();
            $(this).trigger('search:filter', event);

            // Cancel previous request
            $xhr && $xhr.abort && $xhr.abort();

            $xhr = $.ajax({
                url: refinementUrl,
                data: {
                    page: $('.grid-footer').data('page-number'),
                    selectedUrl: refinementUrl
                },
                method: 'GET',
                timeout: 10000,
                beforeSend: function () {
                    $.spinner().start();
                },
                success: function (response) {
                    module.exports.methods.parseResults(response);
                    history.replaceState(undefined, '', refinementUrl);
                    $('body').trigger('search:filter--success');
                },
                complete: function () {
                    $.spinner().stop();
                }
            });
        });
};

exports.filter = () => $('html').on('click', 'button.filter-results', () => toggleRefinementDrawer('open'));
exports.toggle = () => $('html').on('click', '.js-toggle-filters', (e) => toggleFilters(e));
exports.reset = () => $('html').on('click', '.js-reset', (e) => $('.refinements li button').trigger('click'));
exports.resize = () => {
    var windowWidth = $(window).width();

    $(window).resize(() => {
        // iOS fix: make sure window actually resized before triggering resize functions
        if (windowWidth !== $(window).width()) {
            windowWidth = $(window).width();
            toggleRefinementDrawer('close');
            exports.oneDropdownSlideToggle();
        }
    });
};

exports.methods = {
    parseResults: parseResults
}

module.exports = exports;
